module.exports = {
  siteTitle: 'Vaibhav Gharge | Senior Software Engineer',
  siteDescription:
    'Vaibhav Gharge is a software engineer based in Singapore who specializes in developing exceptional, high-quality cloud based services and applications.',
  siteKeywords:
    'Vaibhav Gharge, Vaibhav Gharge, vaibsgharge, software engineer, backend developer, full-stack developer, javascript, Singapore',
  siteUrl: 'https://vaibhavgharge.com',
  siteLanguage: 'en_US',

  googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk/TODO',

  name: 'Vaibhav Gharge',
  location: 'Singapore',
  email: 'vaibsgharge@gmail.com',
  socialMedia: [
    {
      name: 'DevDotTo',
      url: 'https://dev.to/vaibsgharge',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/vaib-gharge/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/VaibSGharge',
    },
    {
      name: 'Github',
      url: 'https://github.com/vaibsgharge',
    },
  ],

  navLinks: [
    {
      name: '<About/>',
      url: '#about',
    },
    {
      name: '<Experience/>',
      url: '#jobs',
    },
    {
      name: '<Work/>',
      url: '#projects',
    },
    {
      name: '<Contact/>',
      url: '#contact',
    },
  ],

  twitterHandle: '@VaibSGharge',
  googleAnalyticsID: 'UA-139198384-2',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
