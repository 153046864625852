import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360">
    <g>
      <g id="B">
        <path
          d="M44.217,46.098L180.221 314.542 277.904 121.882 154.135 121.882 170.943 154.396 222.754 154.38 180.221 239.286 99.7 78.551 299.521 78.645 316.222 46.098 z"
          fill="#000000"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
