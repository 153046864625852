import React from 'react';

const IconLogo = () => (
  <svg
    id="Logo"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 360 360"
    x="0px"
    y="0px"
    width="60px"
    height="60px">
    <title>Logo</title>
    <polygon
      points="44.217,46.098 180.221,314.542 277.904,121.882 154.135,121.882 170.943,154.396 222.754,154.38 180.221,239.286 
  99.7,78.551 299.521,78.645 316.222,46.098 "
    />
  </svg>
);

export default IconLogo;
